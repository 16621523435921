import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import PouchDB from 'pouchdb';
import PouchdbAuthentication from 'pouchdb-authentication';
import { from, Observable, of } from 'rxjs';
import { CombineLatestOperator } from 'rxjs/internal/observable/combineLatest';
import { map, switchMap, tap } from 'rxjs/operators';
import * as X2JS from 'x2js';
import { loadSampleBanksAction } from '../core/redux/actions/samplebanklist.actions';
import * as fromRoot from '../core/redux/reducers/index.reducer';
import { SampleBank } from '../entities/sample-bank';
import { AuthService } from './auth.service';

//import * as PouchDB from 'pouchdb';
//var PouchDB = require("pouchdb");
//PouchDB.plugin(require('pouchdb-authentication'));
PouchDB.plugin(PouchdbAuthentication);

@Injectable({
  providedIn: 'root',
})
export class DataServiceFirebaseService {
  private db: any;
  private remoteDb: any;
  private remote = 'https://3092bcbf-5c63-4d22-8667-a8be1f97d98a-bluemix.cloudant.com/sampledecks';
  private readonly publicUserId = 'wIpHYbOwRibA6v0tirfI';

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient,
    private store: Store<fromRoot.State>
  ) {
    this.db = new PouchDB('SampleDecks');

    let options = {
      live: true,
      retry: true,
      continuous: true,
    };

    /*
        auth: {
            username: "admin",
                password: "password",
        },
        */

    this.remoteDb = new PouchDB(this.remote, {
      //skip_setup: true,
    });
    console.log('this.remoteDb');
    console.log(this.remoteDb);
  }

  init2() {
    // await this.login();
    console.log('init2');

    this.importDefaultBanks$(false).subscribe((value) => {
      this.store.dispatch(loadSampleBanksAction());
    });
  }

  async login(): Promise<boolean> {
    let p = new Promise<boolean>(
      function (resolve, reject) {
        this.remoteDb.logIn('admin', 'password').then(function (e) {
          console.log('e');
          console.log(e);
          resolve(true);
        });
      }.bind(this)
    );
    return p;

    /*
        this.remoteDb.logIn("admin", "password").then(function (e) {
            console.log("e");
            console.log(e);
            console.log("sync now");
            // this.db.sync(remoteDb, options);
        }.bind(this));
        */
  }

  importDefaultBanks$(importToRemote: boolean): Observable<boolean> {
    return of(null).pipe(
      switchMap((succ) => {
        console.log('delete succ', succ);
        return this.saveDefaultBankToDB(
          'assets/_samplebanks/SampleDecks-Drumset-Classic',
          'SampleDecks-Drumset-classic.json',
          importToRemote
        );
      }),

      switchMap((succ) => {
        return this.saveDefaultBankToDB('assets/_samplebanks/SampleDecks-Drumset-808', 'SampleDecks-Drumset-808.json', importToRemote);
      }),

      switchMap((succ) => {
        return this.saveDefaultBankToDB('assets/_samplebanks/SampleDecks-U96-Das-Boot', 'SampleDecks-U96-Das-Boot.json', importToRemote);
      }),

      map((succ) => {
        console.log('succ imported samplebanks...');
        return succ;
      })
    );
  }

  getSampleBanks$(): Observable<SampleBank[]> {
    // return this.http.get<ItemCategory[]>(this.serverUrl+'/items', this.prepareHeader()).pipe(
    return of(null).pipe(
      tap(() => console.log('>>> getSampleBanks enter', this.publicUserId)),
      switchMap(() =>
        this.afs
          .collection('users')
          .doc(this.publicUserId) // TODO: replace by user id!
          .collection('samplebanks')
          .snapshotChanges()
          .pipe(
            map((actions: any) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as SampleBank;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          )
      )
    );
  }

  getUserSampleBanks$(): Observable<SampleBank[]> {
    if (!this.authService.userData) return of([]);

    // return this.http.get<ItemCategory[]>(this.serverUrl+'/items', this.prepareHeader()).pipe(
    return of(null).pipe(
      tap(() => console.log('>>> getSampleBanks enter', this.authService.userData)),
      switchMap(() =>
        this.afs
          .collection('users')
          .doc(this.authService.userData.uid)
          .collection('samplebanks')
          .snapshotChanges()
          .pipe(
            map((actions: any) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as SampleBank;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          )
      )
    );
  }

  saveDefaultBankToDB(jsonFolder: string, jsonFile: string, saveToRemote: boolean): Observable<any> {
    return of(null).pipe(
      tap(() => console.log('>>> saveDefaultBanksToDB: ', jsonFile)),
      switchMap(() => this.http.get<SampleBank>(jsonFolder + '/' + jsonFile)),
      map((samplebank: SampleBank) => {
        const oldKeyMap = samplebank.keyMap;
        delete samplebank.keyMap;
        samplebank.keyMapping = [];

        console.log('>>> oldKeyMap', oldKeyMap);
        if (oldKeyMap) {
          for (let [key, action] of oldKeyMap) {
            samplebank.keyMapping.push({ key, action });
          }
        }

        return samplebank;
      }),
      map((samplebank: SampleBank) => {
        const oldMidiMap = samplebank.midiMap;
        delete samplebank.midiMap;
        samplebank.midiMapping = [];

        for (let [midi, action] of oldMidiMap) {
          samplebank.midiMapping.push({ midi, action });
        }

        return samplebank;
      }),
      map((samplebank: SampleBank) => {
        const oldMidiOutMap = samplebank.midiOutMap;
        delete samplebank.midiOutMap;
        samplebank.midiOutMapping = [];

        for (let [midi, action] of oldMidiOutMap) {
          samplebank.midiOutMapping.push({ midi, action });
        }

        return samplebank;
      }),
      switchMap((samplebank: SampleBank) => {
        samplebank._id = samplebank.id.toString();
        samplebank.folder = jsonFolder;
        console.log(samplebank);

        return from(
          this.afs
            .collection(`users`)
            .doc(this.publicUserId)
            .collection('samplebanks')
            .doc(samplebank.id + '')
            .set(samplebank)
        ).pipe(
          map((res) => {
            console.log('>>> res', res);
            return true;
          })
        );
      })
    );
  }

  getImageAsBlob(imgFile: string): Observable<Blob> {
    return this.http.get(imgFile, { responseType: 'blob' });
  }

  dbPut(doc: any): Observable<boolean> {
    return new Observable((observer) => {
      this.db.put(doc).then((value) => {
        observer.next(value.ok);
        observer.complete();
      });
    });
  }

  /*
    remoteDbPut(doc: any): Observable<boolean> {
        return new Observable((observer) => {
            this.remoteDb.put(doc).then((value) => {
                observer.next(value.ok);
                observer.complete();
            });
        })
    }
    */

  convertSampleBankV3ToV4(): boolean {
    var x2js = new X2JS();
    let xml = '<samplebank><name>SampleDecks Drumset 1</name><description></description></samplebank>';
    var jsonDoc = x2js.xml2js(xml);
    var sampleBank = jsonDoc['samplebank'] as SampleBank;
    console.log('json', jsonDoc);
    console.log('sampleBank');
    console.log(sampleBank);
    return true;
  }

  saveSampleBank$(sampleBank: SampleBank): Observable<boolean> {
    return from(
      this.afs
        .collection(`users`)
        .doc(this.authService.userData.uid)
        .collection('samplebanks')
        // .doc(sampleBank.id + '')
        // .set(sampleBank)
        .add(sampleBank)
    ).pipe(
      map((res) => {
        console.log('>>> res', res);
        return true;
      })
    );
  }

  deleteSampleBank$(sampleBank: SampleBank): Observable<any> {
    alert('deleteSampleBank: ' + sampleBank.id);
    return from(
      this.afs
        .collection(`users`)
        .doc(this.authService.userData.uid)
        .collection('samplebanks')
        .doc(sampleBank.id + '')
        .delete()
    ).pipe(
      tap(() => {
        console.log('>>> deleted');
      })
    );
  }
}
