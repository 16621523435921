import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AudioContextModule } from 'angular-audio-context';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GeneralEffects } from './core/redux/effects/general.effects';
import { SamplebankslistEffects } from './core/redux/effects/samplebankslist.effects';
import { SamplerEffects } from './core/redux/effects/sampler.effects';
import * as generalState from './core/redux/reducers/general.reducer';
import { reducers } from './core/redux/reducers/index.reducer';
import * as sampleBanksListState from './core/redux/reducers/samplebankslist.reducer';
import { HomePageModule } from './home/home.module';
import { SampleSlotEditorListComponent } from './home/sample-slot-editor/sample-slot-editor-list/sample-slot-editor-list.component';
import { SampleSlotEditorComponent } from './home/sample-slot-editor/sample-slot-editor.component';
import { SlotAppearanceSettingsComponent } from './home/sample-slot-editor/slot-appearance-settings/slot-appearance-settings.component';
import { SlotAppearanceSettingsModule } from './home/sample-slot-editor/slot-appearance-settings/slot-appearance-settings.module';
import { SlotMidiSettingsComponent } from './home/sample-slot-editor/slot-midi-settings/slot-midi-settings.component';
import { SlotSampleSettingsComponent } from './home/sample-slot-editor/slot-sample-settings/slot-sample-settings.component';
import { SlotSampleSettingsModule } from './home/sample-slot-editor/slot-sample-settings/slot-sample-settings.module';
import { LoginModule } from './login/login.module';
import { CustomReuseStrategy } from './shared/custom-reuse-strategy';
import { SharedModule } from './shared/shared.module';
import { SlotEditorModule } from './slot-editor/slot-editor.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    /*SlotMidiSettingsComponent,
    SlotSampleSettingsComponent,
    SampleSlotEditorComponent,
    SampleSlotEditorListComponent,
    SlotAppearanceSettingsComponent,
    */
  ],

  imports: [
    BrowserModule,
    SlotSampleSettingsModule,
    AudioContextModule.forRoot('balanced'),
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    HomePageModule,
    SlotEditorModule,
    LoginModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,

    SlotAppearanceSettingsModule,
    StoreModule.forFeature('general', generalState.reducer),
    StoreModule.forFeature('sampleBanksList', sampleBanksListState.reducer),
    EffectsModule.forFeature([GeneralEffects]),
    EffectsModule.forRoot([SamplerEffects, SamplebankslistEffects]),

    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
  ],
  providers: [
    //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
