<div class="content">
  <div class="logo"><img src="assets/img/sd_logo2.png" /></div>
  <div class="container" id="container" [ngClass]="{ 'right-panel-active': rightPanelActive }">
    <div class="form-container forget-password-container" [style.visibility]="!showForgetPasswordContainer ? 'hidden' : 'visible'">
      <form name="forgotPasswordForm" #forgotPasswordForm="ngForm">
        <h1>Reset your password</h1>

        <p class="hint">Please enter your email address to request a password reset.</p>
        <input type="email" id="email" #email="ngModel" [(ngModel)]="forgotPasswordModel.email" name="email" placeholder="Email" required />

        <app-button
          (buttonClick)="handleForgotPassword()"
          cssClass="signInButton"
          type="submit"
          [highlight]="false"
          text="Send link"
          [progress]="signingIn"
        ></app-button>

        <section class="social-section">
          <span>or use your account</span>

          <div class="social-container">
            <div class="social"><i class="fab fa-facebook-f"></i></div>
            <div class="social" (click)="handleSignInWithGoogle()"><i class="fab fa-google-plus-g"></i></div>
            <div class="social"><i class="fab fa-linkedin-in"></i></div>
          </div>
        </section>
      </form>
    </div>

    <div class="form-container sign-up-container" [style.visibility]="!showSignUpContainter ? 'hidden' : 'visible'">
      <form name="signUpForm" action="#" onSubmit="return false;">
        <h1>Create Account</h1>
        <div class="social-container">
          <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
          <a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
          <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
        </div>
        <span>or use your email for registration</span>
        <!--<input type="text" placeholder="Name" />//-->
        <input type="email" placeholder="Email" #userEmail required />
        <input type="password" placeholder="Password" #userPassword required />
        <button (click)="handleSignUp(userEmail, userPassword)">Sign Up</button>
      </form>
    </div>
    <div class="form-container sign-in-container">
      <form name="signInForm" #signInForm="ngForm">
        <h1>Sign in</h1>
        <div class="social-container">
          <div class="social"><i class="fab fa-facebook-f"></i></div>
          <div class="social" (click)="handleSignInWithGoogle()"><i class="fab fa-google-plus-g"></i></div>
          <div class="social"><i class="fab fa-linkedin-in"></i></div>
        </div>
        <span>or use your account</span>
        <input type="email" id="name" #name="ngModel" [(ngModel)]="signInModel.userName" name="userName" placeholder="Email" required />
        <input
          type="password"
          id="password"
          #userPassword="ngModel"
          [(ngModel)]="signInModel.userPassword"
          name="userPassword"
          placeholder="Password"
          required
        />
        <a (click)="handleShowForgetPassword()" nohref class="text-link">Forgot your password?</a>
        <!--<a (click)="handleForgetPassword()" routerLink="/forgot-password">Forgot your password?</a>//-->
        <app-button
          (buttonClick)="onSubmit()"
          cssClass="signInButton"
          type="submit"
          [highlight]="false"
          text="Sign In"
          [progress]="signingIn"
        ></app-button>
      </form>
    </div>

    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-left">
          <h1>Welcome Back!</h1>
          <p>To keep connected with us please login with your personal info</p>
          <button class="ghost" (click)="handleSignIn()">Sign In</button>
        </div>
        <div class="overlay-panel overlay-right">
          <!--<h1 style="display: none">Welcome to SampleDecks.</h1>//-->
          <div class="logo2"><img src="assets/img/sd_logo2.png" /></div>
          <p>Start playing with cool samples and create your personal account now.</p>
          <button class="ghost" (click)="handleShowSignUp()">Sign Up</button>
        </div>
      </div>
    </div>
  </div>
</div>
