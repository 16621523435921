<ion-button
  counting
  [type]="type"
  [fill]="highlight ? 'solid' : 'outline'"
  (click)="handleClick($event)"
  [ngClass]="cssClass"
  [size]="size"
  [class.btn-without-icon]="buttonWithoutIcon"
  [disabled]="disabled || progress"
>
  <ng-container data-slot="text" *ngIf="!text"><ng-content select="[data-slot='text']"></ng-content></ng-container>
  <ng-container data-slot="text" *ngIf="text">{{ text }}</ng-container>

  <div class="icon-or-spinner" slot="end">
    <ion-spinner></ion-spinner>
    <ng-content select="[data-slot='icon']" #icon></ng-content>
  </div>
</ion-button>
