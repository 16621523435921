import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ColorCircleModule } from 'ngx-color/circle';
import { SlotSampleSettingsComponent } from './slot-sample-settings.component';

const routes: Routes = [
  {
    path: '',
    component: SlotSampleSettingsComponent
  }
];

@NgModule({
  declarations: [SlotSampleSettingsComponent],
  imports: [CommonModule, IonicModule, ColorCircleModule],
  exports: [SlotSampleSettingsComponent]
})
export class SlotSampleSettingsModule {}
