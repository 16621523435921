import {PlayAction, SamplerActions} from "../actions/sampler.actions";
import {ActionWithPayload} from "../actions/action.interface";

export interface State {
    slots: {};

}

const initialState: State = {
    slots: {},
};

export function reducer(state: State = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        /*
        case SamplerActions.PLAY: {
            return {
                ...state, playingSlot: (action as PlayAction).slotNo
            };
        }
        */
        case SamplerActions.PLAY_EXECUTED: {
            var slots = {...state.slots};
            slots[(action as PlayAction).payload.slotNo] = true;
            return {
                ...state, slots
            };
        }
        case SamplerActions.STOP_EXECUTED: {
            var slots = {...state.slots};
            slots[(action as PlayAction).payload.slotNo] = false;
            return {
                ...state, slots
            };
        }
        default: {
            return state;
        }
    }
}

// All getter of general state
export const getSlots = (state: State) => state.slots;
