import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { User } from '../entities/user';
import { Location } from '@angular/common';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: User; // Save logged in user data

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private location: Location,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    this.userData = JSON.parse(localStorage.getItem('user'));

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      this.updateUserInStorage(user);
      if (user) {
        //      this.router.navigate(['/home']);
      } else {
        //      this.router.navigate(['/sign-in']);
      }
      console.log('>>> authState sub: this.userData ', this.userData);
    });
  }

  private updateUserInStorage(user) {
    if (user) {
      this.userData = user;
      localStorage.setItem('user', JSON.stringify(this.userData));
      JSON.parse(localStorage.getItem('user'));
    } else {
      localStorage.setItem('user', null);
      JSON.parse(localStorage.getItem('user'));
    }
  }

  // Sign in with email/password
  signIn$(email, password): Observable<void> {
    return of(null).pipe(
      switchMap(() =>
        from(this.afAuth.signInWithEmailAndPassword(email, password)).pipe(
          switchMap((userCredentials) => this.updateUserData$(userCredentials.user).pipe(catchError((error) => of(null)))),
          tap(() => this.router.navigate(['/home'])),
          catchError((error) => {
            window.alert(error.message);
            throw error;
          })
        )
      )
    );
  }

  // Sign up with email/password
  signUp(email, password) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.sendVerificationMail();
        this.updateUserData$(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  async sendVerificationMail() {
    return (await this.afAuth.currentUser).sendEmailVerification().then(() => {
      this.router.navigate(['verify-email-address']);
    });
  }

  // Reset Forggot password
  forgotPassword(passwordResetEmail) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    // const user = JSON.parse(localStorage.getItem('user'));
    //return user !== null && user.emailVerified !== false ? true : false;
    return this.userData !== null && this.userData.emailVerified !== false ? true : false;
  }

  // Sign in with Google

  googleAuth$(): Observable<void | firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.authLogin$(provider);
  }

  // Auth logic to run auth providers
  authLogin$(provider): Observable<void | firebase.auth.UserCredential> {
    return of(null).pipe(
      switchMap(() => from(this.afAuth.signInWithPopup(provider))),
      switchMap((userCredentials: firebase.auth.UserCredential) => this.updateUserData$(userCredentials.user)),
      tap((userCredentials) => {
        console.log('>>> userCredentials', userCredentials);
        this.ngZone.run(() => {
          this.router.navigate(['/home']);
        });
      }),
      catchError((error) => {
        window.alert(error);
        throw error;
      })
    );
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  updateUserData$(user: User): Observable<void> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    console.log('>>> userData', userData);

    //return of(null);

    return of(null).pipe(
      switchMap(() =>
        from(
          userRef.set(userData, {
            merge: true,
          })
        )
      ),

      catchError((err) => {
        console.log('>>> err', err);
        throw err;
      })
    );
  }

  // Sign out
  signOut$(): Observable<void> {
    return of(null).pipe(
      switchMap(() => this.afAuth.signOut()),
      tap(() => {
        localStorage.removeItem('user');
        this.ngZone.run(() => {
          this.router.navigate(['../sign-in']);
        });
      })
    );
  }
}
