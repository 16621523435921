<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Sample</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleClose()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <ion-list>
    <ion-list-header>
      Mode
    </ion-list-header>
    <ion-segment class="filter">
      <ion-segment-button value="cue_play">
        Cue-Play
      </ion-segment-button>
      <ion-segment-button value="direct_play">
        Direct-Play
      </ion-segment-button>
      <ion-segment-button value="start_stop">
        Start/Stop
      </ion-segment-button>
    </ion-segment>

    <ion-list-header>
      Effects
    </ion-list-header>
    <ion-item>
      <ion-label>Loop</ion-label>
      <ion-checkbox color="dark" checked="true"></ion-checkbox>
    </ion-item>
    <ion-item>
      <ion-label>Fade-In</ion-label>
      <ion-checkbox color="dark" checked="false"></ion-checkbox>
    </ion-item>
    <ion-item>
      <ion-label>Fade-Outs</ion-label>
      <ion-checkbox color="dark" checked="false"></ion-checkbox>
    </ion-item>

    <ion-list-header>
      Volume
    </ion-list-header>
    <ion-item>
      <ion-range min="0" max="100" step="1" pin="true" value="100" #volumeSlider>
        <ion-icon slot="start" name="volume-off"></ion-icon>
        <ion-icon slot="end" name="volume-high"></ion-icon>
      </ion-range>
      <ion-label style="flex-grow:0;overflow:visible;">{{ volumeSlider.value }}</ion-label>
    </ion-item>
    <ion-list-header>
      Speed
    </ion-list-header>
    <ion-item>
      <ion-range min="0" max="100" step="1" pin="true" value="100" #speedSlider>
        <ion-icon slot="start" size="small" name="dial"></ion-icon>
        <ion-icon slot="end" name="dial"></ion-icon>
      </ion-range>
      <ion-label style="flex-grow:0;overflow:visible;">{{ speedSlider.value }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
