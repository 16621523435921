import { createAction, props } from '@ngrx/store';
import { SampleBank } from '../../../entities/sample-bank';

const ACTION_TYPE = '[general]';

export const showSlotEditorAction = createAction(`${ACTION_TYPE} Showing Slot Editor`);

export const closeSlotEditorAction = createAction(`${ACTION_TYPE} Closing Slot Editor`);

export const openFullScreenModeAction = createAction(`${ACTION_TYPE} Open Full Screen Mode`);
export const closeFullScreenModeAction = createAction(`${ACTION_TYPE} Close Full Screen Mode`);

export const loadSampleBankAction = createAction(`${ACTION_TYPE} Load Sample Bank`, props<{ sampleBank: SampleBank }>());
export const loadSampleBankSucceededAction = createAction(`${ACTION_TYPE} Load Sample Bank succeeded`, props<{ sampleBank: SampleBank }>());

export const showSidebarAction = createAction(`${ACTION_TYPE} Show Sidebar`);
export const hideSidebarAction = createAction(`${ACTION_TYPE} Hide Sidebar`);
