import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import PouchDB from 'pouchdb';
import PouchdbAuthentication from 'pouchdb-authentication';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import * as X2JS from 'x2js';
import { loadSampleBanksAction } from '../core/redux/actions/samplebanklist.actions';
import * as fromRoot from '../core/redux/reducers/index.reducer';
import { SampleBank } from '../entities/sample-bank';

//import * as PouchDB from 'pouchdb';
//var PouchDB = require("pouchdb");
//PouchDB.plugin(require('pouchdb-authentication'));
PouchDB.plugin(PouchdbAuthentication);

@Injectable({
  providedIn: 'root',
})
export class DataServiceService {
  private db: any;
  private remoteDb: any;
  private remote = 'https://3092bcbf-5c63-4d22-8667-a8be1f97d98a-bluemix.cloudant.com/sampledecks';

  constructor(private http: HttpClient, private store: Store<fromRoot.State>) {
    this.db = new PouchDB('SampleDecks');

    let options = {
      live: true,
      retry: true,
      continuous: true,
    };

    /*
        auth: {
            username: "admin",
                password: "password",
        },
        */

    this.remoteDb = new PouchDB(this.remote, {
      //skip_setup: true,
    });
    console.log('this.remoteDb');
    console.log(this.remoteDb);
  }

  init2() {
    // await this.login();

    this.importDefaultBanks(false).subscribe((value) => {
      this.store.dispatch(loadSampleBanksAction());
    });
  }

  async login(): Promise<boolean> {
    let p = new Promise<boolean>(
      function (resolve, reject) {
        this.remoteDb.logIn('admin', 'password').then(function (e) {
          console.log('e');
          console.log(e);
          resolve(true);
        });
      }.bind(this)
    );
    return p;

    /*
        this.remoteDb.logIn("admin", "password").then(function (e) {
            console.log("e");
            console.log(e);
            console.log("sync now");
            // this.db.sync(remoteDb, options);
        }.bind(this));
        */
  }

  importDefaultBanks(importToRemote: boolean): Observable<boolean> {
    return this.deleteDatabase().pipe(
      flatMap((succ) => {
        console.log('delete succ', succ);
        this.db = new PouchDB('SampleDecks');
        return this.saveDefaultBankToDB(
          'assets/_samplebanks/SampleDecks-Drumset-Classic',
          'SampleDecks-Drumset-classic.json',
          importToRemote
        );
      }),
      flatMap((succ) => {
        return this.saveDefaultBankToDB('assets/_samplebanks/SampleDecks-Drumset-808', 'SampleDecks-Drumset-808.json', importToRemote);
      }),
      flatMap((succ) => {
        return this.saveDefaultBankToDB('assets/_samplebanks/SampleDecks-U96-Das-Boot', 'SampleDecks-U96-Das-Boot.json', importToRemote);
      }),
      map((succ) => {
        console.log('succ imported samplebanks...');
        return succ;
      })
    );
  }

  getSampleBanks(): Observable<SampleBank[]> {
    return new Observable<SampleBank[]>((observer) => {
      this.db.allDocs({ include_docs: true }).then((result) => {
        let data: SampleBank[] = [];
        result.rows.map((row) => {
          data.push(row.doc as SampleBank);
        });
        observer.next(data);
        observer.complete();
      });
    });
  }

  deleteDatabase(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.db
        .destroy()
        .then(function () {
          // database destroyed
          observer.next(true);
          observer.complete();
        })
        .catch(function (err) {
          console.log('err');
          console.log(err);
          // error occurred
          observer.next(false);
          observer.complete();
        });
    });
  }

  saveDefaultBankToDB(jsonFolder: string, jsonFile: string, saveToRemote: boolean): Observable<boolean> {
    console.log('saveDefaultBanksToDB: ' + jsonFile);
    return this.http.get<SampleBank>(jsonFolder + '/' + jsonFile).pipe(
      flatMap((samplebank: SampleBank) => {
        samplebank._id = samplebank.id.toString();
        samplebank.folder = jsonFolder;
        console.log(samplebank);
        if (saveToRemote) {
          //return this.remoteDbPut(samplebank);
        } else return this.dbPut(samplebank);
        // this.getImageAsBlob(samplebank.)
      })
      //,map()
    );
  }

  getImageAsBlob(imgFile: string): Observable<Blob> {
    return this.http.get(imgFile, { responseType: 'blob' });
  }

  dbPut(doc: any): Observable<boolean> {
    return new Observable((observer) => {
      this.db.put(doc).then((value) => {
        observer.next(value.ok);
        observer.complete();
      });
    });
  }

  /*
    remoteDbPut(doc: any): Observable<boolean> {
        return new Observable((observer) => {
            this.remoteDb.put(doc).then((value) => {
                observer.next(value.ok);
                observer.complete();
            });
        })
    }
    */

  convertSampleBankV3ToV4(): boolean {
    var x2js = new X2JS();
    let xml = '<samplebank><name>SampleDecks Drumset 1</name><description></description></samplebank>';
    var jsonDoc = x2js.xml2js(xml);
    var sampleBank = jsonDoc['samplebank'] as SampleBank;
    console.log('json', jsonDoc);
    console.log('sampleBank');
    console.log(sampleBank);
    return true;
  }
}
