import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component2.html',
  styleUrls: ['./forgot-password.component2.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(public authService: AuthService) {}

  rightPanelActive = false;

  submitted = false;
  signingIn = false;

  showForgetPasswordContainter = false;
  showSignUpContainter = true;

  signInModel = { userName: '', userPassword: '' };
  ngOnInit() {}

  handleForgetPassword(email) {
    this.authService.forgotPassword(email);
  }

  handleShowSignUp() {
    this.rightPanelActive = true;
    this.showSignUpContainter = true;
    this.showForgetPasswordContainter = false;
  }

  handleShowForgetPassword() {
    this.rightPanelActive = true;
    this.showSignUpContainter = false;
    this.showForgetPasswordContainter = true;
  }

  handleSignUp(userEmail, userPassword) {
    this.authService.signUp(userEmail.value, userPassword.value);
  }

  handleSignIn() {}

  handleSignInWithGoogle() {
    this.authService.googleAuth$().subscribe();
  }

  onSubmit() {}
}
