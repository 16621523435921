import { createAction, props } from '@ngrx/store';
import { SampleBank } from '../../../entities/sample-bank';

export const loadSampleBanksAction = createAction(`§{ACTION_TYPE} Load SampleBanks`);
export const loadSampleBanksSucceededAction = createAction(
  `§{ACTION_TYPE} Load SampleBanks succeeded`,
  props<{ sampleBanks: SampleBank[] }>()
);

export const showFavoritesAction = createAction(`§{ACTION_TYPE} Show Favorites`);

export const showAllSamplebanksAction = createAction(`§{ACTION_TYPE} Show all SampleBanks`);

export const showCreateSamplebankDialogAction = createAction(`§{ACTION_TYPE} Show Create sample bank dialog`);
export const hideCreateSamplebankDialogAction = createAction(`§{ACTION_TYPE} Hide Create sample bank dialog`);
export const createSamplebankAction = createAction(
  `§{ACTION_TYPE} Create SampleBank`,
  props<{ name: string; cols: number; rows: number }>()
);
export const createSamplebankSucceededAction = createAction(
  `§{ACTION_TYPE} Create SampleBank succeeded`,
  props<{ sampleBank: SampleBank }>()
);

export const deleteSampleBankAction = createAction(`§{ACTION_TYPE} Deleting sample bank`, props<{ sampleBank: SampleBank }>());
export const deleteSampleBankSucceededAction = createAction(`§{ACTION_TYPE} Deleting sample bank succeeded`);
