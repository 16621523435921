import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/redux/reducers/index.reducer';
import { SampleSlotEditorListComponent } from './sample-slot-editor-list/sample-slot-editor-list.component';

@Component({
  selector: 'app-sample-slot-editor',
  templateUrl: './sample-slot-editor.component.html',
  styleUrls: ['./sample-slot-editor.component.scss']
})
export class SampleSlotEditorComponent implements OnInit {
  activeAccordion: string;
  showAccordion = false;

  root = SampleSlotEditorListComponent;

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {}

  setActiveAccordion(accordionID: string) {
    this.activeAccordion = accordionID;
    console.log('this.activeAccordion');
    console.log(this.activeAccordion);
  }

  
}
