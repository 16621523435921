<ion-header>
  <ion-toolbar>
    <ion-title>
      Slot Editor
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleClose()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item (click)="handleLoadSampleSettings()" detail>
      <ion-label>Sample</ion-label>
    </ion-item>
    <ion-item (click)="handleLoadAppearanceSettings()" detail> <ion-label>Appearance</ion-label></ion-item>
    <ion-item (click)="handleMidiSettings()" detail> <ion-label>MIDI</ion-label></ion-item>
  </ion-list>
</ion-content>
