<ion-header>
  <ion-toolbar>
    <ion-title>
      MIDI
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleClose()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label>Midi Device</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
