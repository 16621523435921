import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadSampleBankAction, showSidebarAction, showSlotEditorAction } from '../../core/redux/actions/general.actions';
import { PlayAction } from '../../core/redux/actions/sampler.actions';
import * as fromRoot from '../../core/redux/reducers/general.reducer';
import { SampleBank } from '../../entities/sample-bank';
import { AppHeaderComponent } from '../app-header/app-header.component';
import { AuthService } from '../../services/auth.service';
import { BackendService } from '../../services/backend.service';
import { DataServiceFirebaseService } from '../../services/data-service-firebase.service';

@Component({
  selector: 'app-sampler',
  templateUrl: './sampler.component.html',
  styleUrls: ['./sampler.component.scss'],
})
export class SamplerComponent implements OnInit {
  mode: string = 'play';
  @ViewChild('header') header: AppHeaderComponent;

  currentSampleBank$: Observable<SampleBank>;
  sampleBankLoading$: Observable<Boolean>;
  sideBarOpen$: Observable<Boolean>;

  gridColumns: string = '1fr 1fr 1fr 1fr';
  /* 3 x 1 fraction */
  gridRows = '1fr 1fr 1fr 1fr';

  constructor(
    private store: Store<fromRoot.State>,
    private dataService: DataServiceFirebaseService,
    private backendService: BackendService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.currentSampleBank$ = this.store.select(fromRoot.getCurrentSampleBank);
    this.sampleBankLoading$ = this.store.select(fromRoot.isSampleBankLoading);
    this.sideBarOpen$ = this.store.select(fromRoot.isSidebarOpen);

    this.currentSampleBank$.subscribe((sampleBank) => {
      if (!sampleBank) return;

      this.gridColumns = '';
      this.gridRows = '';

      for (let i = 0; i < sampleBank.cols; i++) {
        this.gridColumns += '1fr ';
      }
      for (let i = 0; i < sampleBank.rows; i++) {
        this.gridRows += '1fr ';
      }
    });
    /*
        this.header.section.subscribe((section) => {
            this.mode = section;
        });
        */
  }

  handleEdit() {
    this.store.dispatch(showSlotEditorAction());
  }

  handleLogout() {
    this.authService.signOut$().subscribe();
  }

  handleInit() {
    this.dataService.init2();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // this.key = event.key;
    // todo: nur numbers!
    this.store.dispatch(new PlayAction({ slotNo: parseInt(event.key) - 1 }));
  }

  loadSampleBank(sampleBank: SampleBank) {
    if (sampleBank) {
      this.store.dispatch(loadSampleBankAction({ sampleBank: sampleBank }));
      // this.menu.close();
    }
  }

  showSidebar() {
    this.store.dispatch(showSidebarAction());
  }
}
