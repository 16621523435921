import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  // currentSampleBank: SampleBank;

  constructor(private platform: Platform) {
    this.initializeApp();
  }

  ngOnInit(): void {
    // todo: Menu über Redux? schwierig mit ionic..
  }

  ngAfterViewInit(): void {
    // this.dataService.init2();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      /*
      StatusBar.setStyle({ style: Style.Default });
      StatusBar.setOverlaysWebView({ overlay: true });
      SplashScreen.hide();
      */
    });
  }
}
