import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { SignInComponent } from './login/sign-in/sign-in.component';
import { SignUpComponent } from './login/sign-up/sign-up.component';
import { VerifyEmailComponent } from './login/verify-email/verify-email.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'camera',
    loadChildren: () => import('./camera-page/camera-page.module').then((m) => m.CameraPagePageModule),
  },
  {
    path: 'sloteditor',
    loadChildren: () =>
      import('./home/sample-slot-editor/slot-sample-settings/slot-sample-settings.module').then((m) => m.SlotSampleSettingsModule),
    outlet: 'secondary',
  },
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'register-user', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
