import {ActionWithPayload} from './action.interface';

export enum SamplerActions {
    PLAY = '[Sampler] PLAY',
    PLAY_EXECUTED = '[Sampler] PLAY_EXECUTED',
    STOP_EXECUTED = '[Sampler] STOP_EXECUTED'
}

export class PlayAction implements ActionWithPayload {
    type: string = SamplerActions.PLAY;

    constructor(public payload: { slotNo: number }) {
    }
}

export class PlayExecutedAction implements ActionWithPayload {
    readonly type: string = SamplerActions.PLAY_EXECUTED;

    constructor(public payload: { slotNo: number }) {
    }
}

export class StopExecutedAction implements ActionWithPayload {
    readonly type: string = SamplerActions.STOP_EXECUTED;

    constructor(public payload: { slotNo: number }) {
    }
}


