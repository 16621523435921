<div class="container" id="container" [ngClass]="{ 'right-panel-active': rightPanelActive }">
  <div class="form-container sign-up-container">
    <form name="signUpForm" action="#" onSubmit="return false;">
      <h1>Create Account</h1>
      <div class="social-container">
        <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
        <a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
        <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
      </div>
      <span>or use your email for registration</span>
      <!--<input type="text" placeholder="Name" />//-->
      <input type="email" placeholder="Email" #userEmail required />
      <input type="password" placeholder="Password" #userPassword required />
      <button (click)="handleSignUp(userEmail, userPassword)">Sign Up</button>
    </form>
  </div>
  <div class="form-container sign-in-container">
    <form name="signInForm" #signInForm="ngForm">
      <h1>Reset your password</h1>

      <input type="email" id="name" #name="ngModel" [(ngModel)]="signInModel.userName" name="userName" placeholder="Email" required />

      <app-button
        (buttonClick)="onSubmit()"
        cssClass="signInButton"
        type="submit"
        [highlight]="false"
        text="Sign In"
        [progress]="signingIn"
      ></app-button>

      <span style="margin-top: 50px">or use your account</span>

      <div class="social-container">
        <div class="social"><i class="fab fa-facebook-f"></i></div>
        <div class="social" (click)="handleSignInWithGoogle()"><i class="fab fa-google-plus-g"></i></div>
        <div class="social"><i class="fab fa-linkedin-in"></i></div>
      </div>
    </form>
  </div>
  <div class="overlay-container">
    <div class="overlay">
      <div class="overlay-panel overlay-left">
        <h1>Welcome Back!</h1>
        <p>To keep connected with us please login with your personal info</p>
        <button class="ghost" (click)="handleSignIn()">Sign In</button>
      </div>
      <div class="overlay-panel overlay-right">
        <div class="logo"><img src="assets/img/sd_logo2.png" /></div>
        <!--<h1>Welcome to SampleDecks.</h1>//-->
        <p>Start playing with cool samples and create your personal account now.</p>
        <button class="ghost" (click)="handleShowSignUp()">Sign Up</button>
      </div>
    </div>
  </div>
</div>
