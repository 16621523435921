import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { closeSlotEditorAction } from '../../../core/redux/actions/general.actions';
import * as fromRoot from '../../../core/redux/reducers/index.reducer';
import { SlotAppearanceSettingsComponent } from '../slot-appearance-settings/slot-appearance-settings.component';
import { SlotSampleSettingsComponent } from '../slot-sample-settings/slot-sample-settings.component';
import { SlotMidiSettingsComponent } from '../slot-midi-settings/slot-midi-settings.component';

@Component({
  selector: 'app-sample-slot-editor-list',
  templateUrl: './sample-slot-editor-list.component.html',
  styleUrls: ['./sample-slot-editor-list.component.scss']
})
export class SampleSlotEditorListComponent implements OnInit {
  constructor(private store: Store<fromRoot.State>, public navCtrl: NavController) {}

  ngOnInit() {}

  handleLoadSampleSettings() {
    const nav: HTMLIonNavElement = document.querySelector('ion-nav');
    nav.push(SlotSampleSettingsComponent, {});
  }

  handleLoadAppearanceSettings() {
    const nav: HTMLIonNavElement = document.querySelector('ion-nav');
    nav.push(SlotAppearanceSettingsComponent, {});
  }

  handleMidiSettings() {
    const nav: HTMLIonNavElement = document.querySelector('ion-nav');
    nav.push(SlotMidiSettingsComponent, {});
  }

  handleClose() {
    this.store.dispatch(closeSlotEditorAction());
  }
}
