<ion-header class="header">
  <ion-toolbar class="toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="handleEdit()">Edit</ion-button>
    </ion-buttons>
    <div class="logo"><img src="assets/img/sd_logo.png" /></div>
    <ion-buttons slot="end">
      <div class="row" *ngIf="authService.userData as user">
        <img
          *ngIf="user?.photoURL"
          class="align-self-start mr-5 img-thumbnail rounded-circle"
          height="30"
          src="{{ user.photoURL }}"
          alt="{{ user.displayName }}"
        />
        <div class="media-body">
          Hello: <strong>{{ user.displayName ? user.displayName : 'User' }}</strong>
        </div>
        <ion-button (click)="handleLogout()">Logout</ion-button>
      </div>
      <!--<ion-button (click)="showSidebar()" *ngIf="!(sideBarOpen$ | async)"><ion-icon name="menu"></ion-icon></ion-button>//-->
      <ion-menu-button autoHide="false" (click)="showSidebar()"></ion-menu-button>
      <ion-button (click)="handleInit()">Init</ion-button>
      <!--  *ngIf="!(sideBarOpen$ | async)" -->
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content no-padding class="site-content">
  <ion-router-outlet name="“secondary”"></ion-router-outlet>

  <div class="main">
    <div class="content" [ngStyle]="{ 'grid-template-columns': gridColumns, 'grid-template-rows': gridRows }">
      <div style="color: white" *ngIf="sampleBankLoading$ | async">Loading...</div>
      <app-slot-button
        *ngFor="let slot of (currentSampleBank$ | async)?.samples"
        [slot]="slot"
        [sampleBank]="currentSampleBank$ | async"
      ></app-slot-button>
    </div>
  </div>
</ion-content>
