import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AppButtonComponent } from './components/app-button/app-button.component';

@NgModule({
  declarations: [AppButtonComponent],
  imports: [CommonModule, IonicModule, RouterModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AppButtonComponent],
})
export class SharedModule {}
