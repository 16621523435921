<ion-split-pane contentId="main2">
  <ion-menu contentId="main2" side="end" #menu [ngClass]="{ 'sidebar-closed': !sideBarOpen }">
    <ion-content>
      <div class="sidebar">
        <app-sample-banks-list (selectedList)="loadSampleBank($event)" #sampleBanksList></app-sample-banks-list>
      </div>
    </ion-content>
  </ion-menu>

  <app-sampler id="main2"></app-sampler>

  <!--
  <ion-footer>
      <ion-toolbar class="toolbar">
          <ion-buttons slot="start">
          </ion-buttons>
          <ion-title></ion-title>
          <ion-buttons slot="end">
          </ion-buttons>
      </ion-toolbar>
  </ion-footer>
  
  //-->
</ion-split-pane>
