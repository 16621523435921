import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { createSamplebankAction, hideCreateSamplebankDialogAction } from '../../core/redux/actions/samplebanklist.actions';

@Component({
  selector: 'create-new-sample-bank',
  templateUrl: './create-new-sample-bank.page.html',
  styleUrls: ['./create-new-sample-bank.page.scss'],
})
export class CreateNewSampleBankPage implements OnInit {
  constructor(private store: Store) {}

  formModel = { name: 'Untitled', cols: 4, rows: 4 };

  ngOnInit() {}

  handleCreate() {
    this.store.dispatch(createSamplebankAction({ name: this.formModel.name, cols: this.formModel.cols, rows: this.formModel.rows }));
  }

  handleCancel() {
    this.store.dispatch(hideCreateSamplebankDialogAction());
  }

  handleColsMinus() {
    this.formModel.cols--;
  }
  handleColsPlus() {
    this.formModel.cols++;
  }

  handleRowsMinus() {
    this.formModel.rows--;
  }
  handleRowsPlus() {
    this.formModel.rows++;
  }
}
