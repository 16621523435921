import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

    @Output("section")
    section: EventEmitter<string> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }


    onModeChanged($event) {
        this.section.emit($event.detail.value);
    }


}
