import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SampleBank } from '../../../entities/sample-bank';
import {
  closeFullScreenModeAction,
  hideSidebarAction,
  loadSampleBankAction,
  loadSampleBankSucceededAction,
  showSidebarAction,
} from '../actions/general.actions';

export interface State {
  fullscreenModeOpen: boolean;
  fullscreenImageStartIndex: number;
  currentSampleBank: SampleBank;
  sampleBankLoading: boolean;
  sidebarOpen: boolean;
}

const initialState: State = {
  fullscreenModeOpen: false,
  fullscreenImageStartIndex: 0,
  currentSampleBank: null,
  sampleBankLoading: false,
  sidebarOpen: true,
};

export const generalReducer = createReducer(
  initialState,
  on(closeFullScreenModeAction, (state, action) => {
    return {
      ...state,
      fullscreenModeOpen: false,
    };
  }),
  on(loadSampleBankAction, (state, action) => {
    return {
      ...state,
      sampleBankLoading: true,
    };
  }),
  on(loadSampleBankSucceededAction, (state, action) => {
    return {
      ...state,
      sampleBankLoading: false,
      currentSampleBank: action.sampleBank,
    };
  }),
  on(hideSidebarAction, (state, action) => {
    return {
      ...state,
      sidebarOpen: false,
    };
  }),
  on(showSidebarAction, (state, action) => {
    return {
      ...state,
      sidebarOpen: true,
    };
  })
);

export function reducer(state: State = initialState, action: Action): State {
  return generalReducer(state, action);
}

// Selectors

export const generalState = createFeatureSelector('general');

// All getter of general state
export const isFullscreenModeOpen = createSelector(generalState, (state: State) => state.fullscreenModeOpen);
export const getFullscreenImageStartIndex = createSelector(generalState, (state: State) => state.fullscreenImageStartIndex);
export const isSampleBankLoading = createSelector(generalState, (state: State) => state.sampleBankLoading);
export const isSidebarOpen = createSelector(generalState, (state: State) => state.sidebarOpen);
export const getCurrentSampleBank = createSelector(generalState, (state: State) => state.currentSampleBank);
