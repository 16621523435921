import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IonMenu } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { loadSampleBankAction, showSidebarAction } from '../core/redux/actions/general.actions';
import { loadSampleBanksAction } from '../core/redux/actions/samplebanklist.actions';
import * as fromRoot from '../core/redux/reducers/general.reducer';
import { SampleBank } from '../entities/sample-bank';
import { AuthService } from '../services/auth.service';
import { BackendService } from '../services/backend.service';
import { DataServiceFirebaseService } from '../services/data-service-firebase.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit, AfterViewInit {
  //    currentSampleBank: SampleBank;

  appMode = 'banks';

  @ViewChild('menu', { static: true })
  menu: IonMenu;

  sideBarOpen = true;

  constructor(
    private store: Store<fromRoot.State>,
    private dataService: DataServiceFirebaseService,
    private backendService: BackendService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    // TODO: unsub
    this.store.select(fromRoot.isSidebarOpen).subscribe((open) => {
      this.sideBarOpen = open;
      if (open) {
        // if (this.menu)
        //   this.menu.open();
      } else {
        // if (this.menu)
        // this.menu.close();
      }
    });
  }

  showSidebar() {
    this.store.dispatch(showSidebarAction());
  }

  ngAfterViewInit(): void {
    // alert('userData-id: ' + this.authService.userData?.uid);
    this.store.dispatch(loadSampleBanksAction());
  }

  loadSampleBank(sampleBank: SampleBank) {
    if (sampleBank) {
      this.store.dispatch(loadSampleBankAction({ sampleBank: sampleBank }));
    }
  }
}
