import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slot-sample-settings',
  templateUrl: './slot-sample-settings.component.html',
  styleUrls: ['./slot-sample-settings.component.scss']
})
export class SlotSampleSettingsComponent implements OnInit {
  color: any;
  constructor() {}

  ngOnInit() {}

  handleChooseColor() {}

  changeComplete(event) {
    console.log('event', event);
  }
}
