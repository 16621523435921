<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Sample</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label>Sample</ion-label>
      <ion-button>Choose...</ion-button>
    </ion-item>
    <ion-item>
      <ion-label slot="start">Name</ion-label>
      <ion-text slot="end"></ion-text>
    </ion-item>
    <ion-item>
      <ion-label>Image</ion-label>
      <ion-button>Choose...</ion-button>
    </ion-item>
    <ion-item>
      <ion-label>Color</ion-label>
      <color-circle [color]="color" (onChangeComplete)="changeComplete($event)"></color-circle>
    </ion-item>
  </ion-list>
</ion-content>
