import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SampleBank } from '../../../entities/sample-bank';
import { SampleSlotEditorComponent } from '../../../home/sample-slot-editor/sample-slot-editor.component';
import { BackendService } from '../../../services/backend.service';
import {
  closeSlotEditorAction, loadSampleBankAction,
  loadSampleBankSucceededAction,
  showSlotEditorAction
} from '../actions/general.actions';

@Injectable()
export class GeneralEffects {
  constructor(private actions$: Actions, private backendService: BackendService, private modalCtrl: ModalController) {}

  loadSampleBank$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSampleBankAction),
      switchMap((action) => {
        return this.backendService.loadSampleBank(action.sampleBank); // todo: error handling
      }),
      map(
        (sampleBank: SampleBank) => {
          return loadSampleBankSucceededAction({ sampleBank: sampleBank });
        },
        (error) => {
          console.error(error);
          // return CompilationActions.loadLocalCompilationsFail();
        }
      )
    )
  );

  showSlotEditor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSlotEditorAction),
        switchMap((action) => {
          const s = new Observable((observer) => {
            this.modalCtrl
              .create({
                component: SampleSlotEditorComponent,
                componentProps: {},
              })
              .then((modal) => {
                modal.present();
                observer.next();
              });
          });

          return s;
        }),
        map(() => {})
      ),
    { dispatch: false }
  );

  closeSlotEditor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeSlotEditorAction),
        tap((action) => {
          this.modalCtrl.dismiss();
        })
      ),
    { dispatch: false }
  );
}
