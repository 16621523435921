import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromSampler from './sampler.reducer';

export interface State {
  sampler: fromSampler.State;
}

export const reducers: ActionReducerMap<State> = {
  sampler: fromSampler.reducer,
};

/**
 * All getState-methods goes here
 */

//Sampler
export const getSamplerState = (state: State) => state.sampler;
export const getSlots = createSelector(getSamplerState, fromSampler.getSlots);
