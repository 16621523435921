<div class="header">
    <div class="logo"><img src="assets/img/sd_logo.png"></div>
    <div class="mode-pane">
        <div class="label">MODUS</div>
        <!-- Default Segment -->
        <!--
        <div class="segmented-bar">
            <ion-segment (ionChange)="onModeChanged($event)">
                <ion-segment-button value="banks" [checked]="true">
                    Banks
                </ion-segment-button>
                <ion-segment-button value="play">
                    Play
                </ion-segment-button>
                <ion-segment-button value="editor">
                    Edit
                </ion-segment-button>
            </ion-segment>
        </div>
        //-->

    </div>
    <!--
    <div class="time">12:00</div>
    //-->
</div>
