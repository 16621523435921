import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SampleBank } from '../../../entities/sample-bank';
import { CreateNewSampleBankPage } from '../../../home/create-new-sample-bank/create-new-sample-bank.page';
import { BackendService } from '../../../services/backend.service';
import { DataServiceFirebaseService } from '../../../services/data-service-firebase.service';
import { loadSampleBankAction } from '../actions/general.actions';
import {
  createSamplebankAction,
  createSamplebankSucceededAction,
  deleteSampleBankAction,
  deleteSampleBankSucceededAction,
  hideCreateSamplebankDialogAction,
  loadSampleBanksAction,
  loadSampleBanksSucceededAction,
  showCreateSamplebankDialogAction,
} from '../actions/samplebanklist.actions';

@Injectable()
export class SamplebankslistEffects {
  constructor(
    private actions$: Actions,
    private backendService: BackendService,
    private modalController: ModalController,
    private dataService: DataServiceFirebaseService
  ) {}

  loadSampleBanks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSampleBanksAction),
      switchMap((action) => {
        return zip(this.backendService.getSampleBanks$(), this.backendService.getUserSampleBanks$());
        /*
        let sampleBanks = new Array<SampleBank>();
        return this.backendService.getSampleBanks$().pipe(
          switchMap((sharedSampleBanks: SampleBank[]) =>
            this.backendService.getUserSampleBanks$().pipe(
              map((userSampleBanks: SampleBank[]) => {
                sampleBanks.push(...userSampleBanks);
                sampleBanks.push(...sharedSampleBanks);
                return sampleBanks;
              })
            )
          )
           );
          */
      }),
      map((sampleBanks: Array<SampleBank[]>) => {
        console.log('erffect: sampleBanks', sampleBanks);
        const mergeSampleBanks = [...sampleBanks[0], ...sampleBanks[1]];
        return loadSampleBanksSucceededAction({ sampleBanks: mergeSampleBanks });
      })
    )
  );

  showCreateSamplebankDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showCreateSamplebankDialogAction),
        switchMap((action) => this.modalController.create({ component: CreateNewSampleBankPage, componentProps: {} })),
        switchMap((dialog) => dialog.present())
      ),
    { dispatch: false }
  );

  hideCreateSamplebankDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideCreateSamplebankDialogAction),
        switchMap((action) => this.hideModalDialog())
      ),
    { dispatch: false }
  );

  createSamplebank$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSamplebankAction),
        map((action) => ({ name: action.name, samples: [], cols: action.cols, rows: action.rows } as SampleBank)),
        switchMap((sampleBank: SampleBank) => this.backendService.saveSampleBank$(sampleBank).pipe(map(() => sampleBank))),
        map((sampleBank: SampleBank) => createSamplebankSucceededAction({ sampleBank }))
      ),
    { dispatch: true }
  );

  createSamplebankSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSamplebankSucceededAction),
        switchMap((action) => this.hideModalDialog())
      ),
    { dispatch: false }
  );

  deleteSampleBank$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSampleBankAction),
      switchMap((action) => this.backendService.deleteSampleBank$(action.sampleBank)),
      map(() => loadSampleBanksAction()) // deleteSampleBankSucceededAction
    )
  );

  private hideModalDialog(): Observable<any> {
    return from(this.modalController.dismiss());
  }
}
