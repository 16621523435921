<ion-header class="header">
  <ion-toolbar class="toolbar">
    <ion-buttons slot="start"> </ion-buttons>
    <ion-title>Samplebanks</ion-title>
    <ion-buttons slot="end">
      <ion-menu-button autoHide="false" (click)="hideSidebar()"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="sample-banks-list">
  <!-- Default Segment -->
  <ion-segment class="filter" (ionChange)="onSegmentChanged($event)" [value]="segmentMode$ | async">
    <ion-segment-button value="all"> All </ion-segment-button>
    <ion-segment-button value="favorites"> Favorites </ion-segment-button>
  </ion-segment>
  <ion-searchbar debounce="500" placeholder="Search SampleBanks..."></ion-searchbar>
  <div class="loader" *ngIf="sampleBanksLoading$ | async">
    <ion-spinner *ngIf="sampleBanksLoading$ | async"></ion-spinner>
  </div>
  <div class="item-list" *ngIf="!(sampleBanksLoading$ | async)">
    <div
      class="item"
      *ngFor="let sampleBank of sampleBanks$ | async"
      (click)="handleListItemClick(sampleBank)"
      [ngClass]="{ active: _selectedList == sampleBank }"
    >
      <img class="image" src="data:image/jpeg;base64,{{ sampleBank.previewImage }}" width="52" height="30" />
      <div class="description">
        <div class="name">{{ sampleBank.name }}</div>
        <div class="subtext">24.08.2018 - {{ sampleBank?.samples?.length }} Slots ({{ sampleBank?.cols }}x{{ sampleBank.rows }})</div>
      </div>
    </div>
  </div>
</ion-content>
<!--
<ion-footer>
    <ion-toolbar class="toolbar_">
        <ion-buttons slot="start">
            <ion-button fill="clear" (click)="createSampleBank()">
                <ion-icon slot="icon-only" name="add"></ion-icon>
            </ion-button>

        </ion-buttons>
        <ion-title>footer</ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="removeSampleBank()">
                <ion-icon slot="icon-only" name="remove"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
//-->

<div class="footer">
  <div class="start-slot">
    <button (click)="handleCreateSampleBank()">
      <ion-icon slot="icon-only" name="add"></ion-icon>
    </button>
    <button (click)="handleRemoveSampleBank()">
      <ion-icon slot="icon-only" name="remove"></ion-icon>
    </button>
  </div>

  <div class="end-slot"></div>
</div>
