import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { hideSidebarAction } from '../../core/redux/actions/general.actions';
import {
  deleteSampleBankAction,
  showAllSamplebanksAction,
  showCreateSamplebankDialogAction,
  showFavoritesAction,
} from '../../core/redux/actions/samplebanklist.actions';
import * as fromRoot from '../../core/redux/reducers/index.reducer';
import { getSampleBanks, getSegmentMode, isSampleBanksListLoading } from '../../core/redux/reducers/samplebankslist.reducer';
import { SampleBank } from '../../entities/sample-bank';
import { BackendService } from '../../services/backend.service';
import { DataServiceService } from '../../services/data-service.service';

@Component({
  selector: 'app-sample-banks-list',
  templateUrl: './sample-banks-list.component.html',
  styleUrls: ['./sample-banks-list.component.scss'],
})
export class SampleBanksListComponent implements OnInit {
  //items: SampleBank[] = [];
  @Output('selectedList')
  selectedList: EventEmitter<SampleBank> = new EventEmitter();
  _selectedList: SampleBank;

  sampleBanksLoading$: Observable<boolean>;
  sampleBanks$: Observable<SampleBank[]>;

  segmentMode$: Observable<string> = this.store.select(getSegmentMode);

  constructor(
    private backendService: BackendService,
    private store: Store<fromRoot.State>,
    private dataService: DataServiceService,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.sampleBanksLoading$ = this.store.select(isSampleBanksListLoading);
    this.sampleBanks$ = this.store.select(getSampleBanks);

    // TOOD: Was ist das?
    this.sampleBanks$.subscribe((sampleBanks) => {
      if (sampleBanks) {
        this.handleListItemClick(sampleBanks[1]);
      }
    });
  }

  handleListItemClick(sampleBank: SampleBank) {
    this._selectedList = sampleBank; //todo: zusammenbringen, evtl. gleich Redux?
    this.selectedList.emit(sampleBank);
  }

  onSegmentChanged(segment) {
    if (segment.detail.value == 'favorites') {
      this.store.dispatch(showFavoritesAction());
    } else {
      this.store.dispatch(showAllSamplebanksAction());
    }
  }

  hideSidebar() {
    this.store.dispatch(hideSidebarAction());
  }

  handleCreateSampleBank() {
    this.store.dispatch(showCreateSamplebankDialogAction());
  }

  handleRemoveSampleBank() {
    this.store.dispatch(deleteSampleBankAction({ sampleBank: this._selectedList }));
  }
}
