import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from "rxjs";
import {BackendService} from "../../../services/backend.service";
import {map} from "rxjs/operators";
import {ActionWithPayload} from "../actions/action.interface";
import {PlayAction, PlayExecutedAction, SamplerActions} from "../actions/sampler.actions";

@Injectable()
export class SamplerEffects {
    constructor(private actions$: Actions, private backendService: BackendService) {
    }

    @Effect()
    play$: Observable<ActionWithPayload> = this.actions$
        .pipe(
            ofType(SamplerActions.PLAY),
            map((action) => {

                let playAction = action as PlayAction;
                this.backendService.playSample2(playAction.payload.slotNo);   //todo: error handling
                return action;
            }),
            map((action) => {
                let playAction = action as PlayAction;
                return new PlayExecutedAction({slotNo: playAction.payload.slotNo});
            }, error => {
                console.error(error);
                //return CompilationActions.loadLocalCompilationsFail();
            }));
}