import {Component, Input, OnInit} from '@angular/core';
import {SampleBankSlot} from "../../entities/sample-bank-slot";
import {BackendService} from "../../services/backend.service";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../core/redux/reducers/index.reducer";
import {PlayAction} from "../../core/redux/actions/sampler.actions";
import {SampleBank} from "../../entities/sample-bank";
import * as moment from 'moment';


@Component({
    selector: 'app-slot-button',
    templateUrl: './slot-button.component.html',
    styleUrls: ['./slot-button.component.scss']
})
export class SlotButtonComponent implements OnInit {

    @Input("slot")
    slot: SampleBankSlot;

    @Input("sampleBank")
    sampleBank: SampleBank;

    progress: number = 0.0;
    playing: boolean = false;
    animationLength: string = "1s";
    slotLength: string = "";

    constructor(private backendService: BackendService, private store: Store<fromRoot.State>) {
    }

    ngOnInit() {
        this.animationLength = (this.slot.length / 1000.0).toString() + "s";
        this.backendService.loadSample$(this.slot, this.sampleBank).subscribe((loaded: boolean) => {
            this.slotLength = moment.utc(this.slot.length).format("mm:ss");
        });

        this.store
            .pipe(select(fromRoot.getSlots))
            .subscribe((slots) => {
                this.playing = slots[this.slot.slotNo];//todo: to opmitize
            });
    }

    playSample() {
        this.store.dispatch(new PlayAction({slotNo: this.slot.slotNo}));
    }

    playSampleByTouch(e) {
        e.preventDefault();
        this.store.dispatch(new PlayAction({slotNo: this.slot.slotNo}));
    }


    getSlotImage() {
        return `url(${this.sampleBank.folder}/${this.slot.image})`;
    }

    getProgress() {
        return this.progress * 100 + "%";
    }

    preventClick(e) {
        e.preventDefault();
    }
}
