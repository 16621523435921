// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiHost: "localhost",
    //apiHost: "192.168.178.42",
    apiPort: 8084,
  // Firebase
  firebase: {    
    // databaseURL: 'https://shelfdesigner-bbbcb.firebaseio.com',
    apiKey: "AIzaSyCyQboEbCifJOmyUE2OJBBb0MZMHNrmjHY",
    authDomain: "sampledecks-4e2e7.firebaseapp.com",
    projectId: "sampledecks-4e2e7",
    storageBucket: "sampledecks-4e2e7.appspot.com",
    messagingSenderId: "481766083033",
    appId: "1:481766083033:web:a64676319ef6fb1071f1c8",
    measurementId: "G-T5XTST9732"
  }

};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
