import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnimationController } from '@ionic/angular';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppButtonComponent implements OnInit {
  @Input() text: string;
  @Input() type: string = 'button';
  @Input() highlight: boolean = false;
  @Input('cssClass') cssClass = '';
  @Input('disabled') disabled = false;
  @Input('fill') fill = '';
  @Input('size') size = 'default';
  @Input() buttonWithoutIcon: boolean;
  @Output('buttonClick') buttonClick = new EventEmitter();

  private _progress = false;

  constructor(private animationController: AnimationController, private el: ElementRef) {}

  ngOnInit(): void {}

  public get progress() {
    return this._progress;
  }
  @Input('progress') public set progress(newValue) {
    this._progress = newValue;
    this.disabled = newValue;

    if (this._progress) {
      this.showProgress();
    } else {
      this.hideProgress();
    }
  }

  showProgress() {
    const spinnerWidth = getComputedStyle(this.el.nativeElement.querySelector('ion-spinner')).width;
    const endSlotWidth = getComputedStyle(this.el.nativeElement.querySelector('[slot="end"]')).getPropertyValue('width');
    const buttonContainsIcon = this.el.nativeElement.querySelector('[data-slot="icon"]') !== null;
    const endSlot = this.el.nativeElement.querySelector('[slot="end"]');

    let hideIconAnimation = this.animationController
      .create()
      .addElement(this.el.nativeElement.querySelector("[data-slot='icon']"))
      .iterations(1)
      .fromTo('transform', 'scale(1)', 'scale(0)')
      .fromTo('opacity', '1', '0');

    let disableTextAnimation = this.animationController
      .create()
      .addElement(this.el.nativeElement.querySelector("[data-slot='text']"))
      .iterations(1)
      .fromTo('opacity', '1', '0.5');

    let extendButtonAnimation = this.animationController
      .create()
      .addElement(endSlot)
      .to('width', `calc(${endSlotWidth} + ${spinnerWidth})`);

    let showProgressAnimation = this.animationController
      .create()
      .addElement(this.el.nativeElement.querySelector('ion-spinner'))
      .iterations(1)
      .fromTo('transform', 'scale(0)', 'scale(1)')
      .fromTo('opacity', '0', '1');

    let animation = this.animationController
      .create()
      .duration(200)
      .iterations(1)
      .addAnimation([disableTextAnimation, hideIconAnimation, showProgressAnimation]);

    if (!buttonContainsIcon) {
      animation.addAnimation(extendButtonAnimation);
    }

    animation.play();
  }

  hideProgress() {
    const spinnerWidth = getComputedStyle(this.el.nativeElement.querySelector('ion-spinner')).width;
    const buttonContainsIcon = this.el.nativeElement.querySelector('[data-slot="icon"]') !== null;

    const endSlot = this.el.nativeElement.querySelector('[slot="end"]');
    const endSlotWidth = getComputedStyle(endSlot).width;

    let enableTextAnimation = this.animationController
      .create()
      .addElement(this.el.nativeElement.querySelector("[data-slot='text']"))
      .iterations(1)
      .to('opacity', 1);

    let showIconAnimation = this.animationController
      .create()
      .addElement(this.el.nativeElement.querySelector("[data-slot='icon']"))
      .iterations(1)
      .fromTo('transform', 'scale(0)', 'scale(1)')
      .to('opacity', 1);

    let collapseButtonAnimation = this.animationController
      .create()
      .addElement(endSlot)
      .to('width', `calc(${endSlotWidth} - ${spinnerWidth})`);

    let hideProgressAnimation = this.animationController
      .create()
      .addElement(this.el.nativeElement.querySelector('ion-spinner'))
      .iterations(1)
      .fromTo('transform', 'scale(1)', 'scale(0)')
      .to('opacity', 0);

    let animation = this.animationController
      .create()
      .duration(200)
      .iterations(1)
      .addAnimation([enableTextAnimation, showIconAnimation, hideProgressAnimation]);

    if (!buttonContainsIcon) {
      animation.addAnimation(collapseButtonAnimation);
    }

    animation.play();
  }

  handleClick(event) {
    if (!this.disabled) {
      this.buttonClick.next();
    }
  }
}
