import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SampleBank } from '../entities/sample-bank';
import { SampleBankSlot } from '../entities/sample-bank-slot';
import { AudioContext } from 'angular-audio-context';

import { environment } from '../../environments/environment';
import { DataServiceService } from './data-service.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../core/redux/reducers/index.reducer';
import { StopExecutedAction } from '../core/redux/actions/sampler.actions';
import { DataServiceFirebaseService } from './data-service-firebase.service';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  
  private apiURL: string;
  private apiURL2: string;
  slots: AudioBuffer[] = new Array<AudioBuffer>(16);

  constructor(
    private http: HttpClient,
    private audioContext: AudioContext,
    private dataService: DataServiceService,
    private dataServiceFirebase: DataServiceFirebaseService,
    private store: Store<fromRoot.State>
  ) {
    this.apiURL = `http://${environment.apiHost}:${environment.apiPort}`;
    this.apiURL2 = `assets`;
    //this.apiURL = `http://192.168.178.42:8080`;
    //this.apiURL = `http://localhost:8080`;
  }

  getSampleBanks$(): Observable<SampleBank[]> {
    //return this.http.get<SampleBank[]>(`${this.apiURL}/samplebanks`);
    //
    // return this.http.get<SampleBank[]>(`${this.apiURL2}/_samplebanks/samplebanks.json`);

    // return this.dataService.getSampleBanks();
    return this.dataServiceFirebase.getSampleBanks$();
  }

  saveSampleBank$(sampleBank: SampleBank): Observable<boolean> {
    return this.dataServiceFirebase.saveSampleBank$(sampleBank);
  }

  getUserSampleBanks$(): Observable<SampleBank[]> {
    return this.dataServiceFirebase.getUserSampleBanks$();
  }

  playSample(slot: SampleBankSlot): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiURL}/playsample?slotNo=${slot.slotNo}`);
  }

  onSampleEnded(slotNo: number) {
    this.store.dispatch(new StopExecutedAction({ slotNo: slotNo }));
  }

  playSample2(slotNo: number) {
    let bufferSource = this.audioContext.createBufferSource();
    bufferSource.onended = function () {
      this.onSampleEnded(slotNo);
    }.bind(this);
    bufferSource.buffer = this.slots[slotNo];
    bufferSource.connect(this.audioContext.destination);
    bufferSource.start(0);
  }

  initAudioEngine(): Observable<boolean> {
    console.log('initaudioengine');
    //return this.http.get<boolean>(`${this.apiURL}/initaudioengine`);
    return new Observable<boolean>((observer) => {
      observer.next(true);
      observer.complete();
    });
  }

  loadSampleBank(sampleBank: SampleBank): Observable<SampleBank> {
    console.log('sampleBank');
    console.log(sampleBank);
    return new Observable((observer) => {
      this.slots = new Array<AudioBuffer>(sampleBank.samples.length);
      observer.next(sampleBank);
      observer.complete();
    });
  }

  loadSample$(slot: SampleBankSlot, sampleBank: SampleBank): Observable<boolean> {
    // return this.http.get<boolean>(`${this.apiURL}/loadsample?slotNo=${slot.no}&file=${slot.file}`);

    return new Observable<boolean>((observer) => {
      this.http.get(`${sampleBank.folder}/${slot.file}`, { responseType: 'arraybuffer' }).subscribe((data) => {
        this.audioContext.decodeAudioData(
          data,
          function (buffer: AudioBuffer) {
            //console.log("slot",slot.slotNo,buffer.length);
            //console.log("slot",slot.slotNo,BackendService.toInt(buffer.duration*1000));
            this.slots[slot.slotNo] = buffer;

            // slot.length = BackendService.toInt(buffer.duration * 1000);
            // slot.realLength = BackendService.toInt(buffer.duration * 1000);
            observer.next(true); //könnte hier raus
            observer.complete();
          }.bind(this)
        );
      });
    });
  }

  deleteSampleBank$(sampleBank: SampleBank): Observable<any> {
    return this.dataServiceFirebase.deleteSampleBank$(sampleBank);
  }

  static toInt(value) {
    return parseInt(value, 10);
  }
}
