import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { AppHeaderComponent } from './app-header/app-header.component';
import { CreateNewSampleBankPage } from './create-new-sample-bank/create-new-sample-bank.page';
import { HomePage } from './home.page';
import { SampleBanksListComponent } from './sample-banks-list/sample-banks-list.component';
import { SampleSlotEditorListComponent } from './sample-slot-editor/sample-slot-editor-list/sample-slot-editor-list.component';
import { SampleSlotEditorComponent } from './sample-slot-editor/sample-slot-editor.component';
import { SlotMidiSettingsComponent } from './sample-slot-editor/slot-midi-settings/slot-midi-settings.component';
import { SamplerComponent } from './sampler/sampler.component';
import { SlotButtonComponent } from './slot-button/slot-button.component';
import { VideoPreviewComponent } from './video-preview/video-preview.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomePage,
      },
    ]),
    IonicModule,
    FormsModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  declarations: [
    HomePage,
    AppHeaderComponent,
    SampleBanksListComponent,
    SlotButtonComponent,
    SampleSlotEditorComponent,
    VideoPreviewComponent,
    SampleSlotEditorListComponent,
    SlotMidiSettingsComponent,
    SamplerComponent,
    CreateNewSampleBankPage,
  ],
  exports: [SampleBanksListComponent, SampleSlotEditorComponent, VideoPreviewComponent],
})
export class HomePageModule {}
