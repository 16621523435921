import { Component, OnInit } from '@angular/core';
import { closeSlotEditorAction } from '../../../core/redux/actions/general.actions';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/redux/reducers/index.reducer';

@Component({
  selector: 'app-slot-appearance-settings',
  templateUrl: './slot-appearance-settings.component.html',
  styleUrls: ['./slot-appearance-settings.component.scss']
})
export class SlotAppearanceSettingsComponent implements OnInit {
  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {}

  handleClose() {
    this.store.dispatch(closeSlotEditorAction());
  }
}
