import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Reuse the route if the RouteConfig is the same, or if both routes use the
    // same component, because the latter can have different RouteConfigs.
    console.log(">>> future.routeConfig", future.routeConfig);
    return (
      future.routeConfig === curr.routeConfig ||
      (!!future.routeConfig?.component && future.routeConfig?.component === curr.routeConfig?.component)
    );
  }
}
