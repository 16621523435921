import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SampleBank } from '../../../entities/sample-bank';
import { loadSampleBankAction } from '../actions/general.actions';
import {
  createSamplebankAction,
  loadSampleBanksAction,
  loadSampleBanksSucceededAction,
  showAllSamplebanksAction,
  showFavoritesAction,
} from '../actions/samplebanklist.actions';
import { createSamplebankSucceededAction } from './../actions/samplebanklist.actions';

export interface State {
  sampleBanksListLoading: boolean;
  sampleBanks: SampleBank[];
  favoritesSelected: boolean;
  segmentMode: 'all' | 'favorites';
}

const initialState: State = {
  sampleBanksListLoading: false,
  sampleBanks: null,
  segmentMode: 'all',
  favoritesSelected: false,
};

export const generalReducer = createReducer(
  initialState,
  on(loadSampleBanksAction, (state, action) => {
    return {
      ...state,
      sampleBanksListLoading: true,
    };
  }),
  on(loadSampleBankAction, (state, action) => {
    return {
      ...state,
      sampleBankLoading: true,
    };
  }),
  on(loadSampleBanksSucceededAction, (state, action) => {
    return {
      ...state,
      sampleBanksListLoading: false,
      sampleBanks: action.sampleBanks,
    };
  }),
  on(showFavoritesAction, (state, action) => {
    return {
      ...state,
      favoritesSelected: true,
    };
  }),
  on(showAllSamplebanksAction, (state, action) => {
    return {
      ...state,
      favoritesSelected: false,
    };
  }),
  on(createSamplebankSucceededAction, (state, action) => {
    let sampleBanks = state.sampleBanks.slice();
    sampleBanks.push(action.sampleBank);
    return {
      ...state,
      sampleBanks,
    };
  }),
  on(createSamplebankSucceededAction, (state, action) => {
    return {
      ...state,
      favoritesSelected: false,
    };
  })
);

export function reducer(state: State = initialState, action: Action): State {
  return generalReducer(state, action);
}

// Selectors

export const sampleBanksListState = createFeatureSelector('sampleBanksList');

export const isSampleBanksListLoading = createSelector(sampleBanksListState, (state: State) => state.sampleBanksListLoading);
export const getSampleBanks = createSelector(sampleBanksListState, (state: State) => state.sampleBanks);
export const isFavoritesSelected = createSelector(sampleBanksListState, (state: State) => state.favoritesSelected);
export const getSegmentMode = createSelector(sampleBanksListState, (state: State) => state.segmentMode);
