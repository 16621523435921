<ion-content padding>
  <h1>Create SampleBank</h1>

  <form name="form" #form="ngForm">
    <ion-item>
      <ion-label>Name</ion-label>
      <ion-input type="text" [autofocus]="true" placeholder="SampleBank Name" name="name" [(ngModel)]="formModel.name"></ion-input>
    </ion-item>

    <div class="grid-size">
      <div class="stepper-with-label">
        <div class="label">Columns</div>
        <div class="stepper">
          <button class="plusminus" (click)="handleColsMinus()">-</button>
          <input type="number" class="num" name="cols" [(ngModel)]="formModel.cols" />
          <button class="plusminus" (click)="handleColsPlus()">+</button>
        </div>
      </div>

      <div class="stepper-with-label">
        <div class="label">Rows</div>
        <div class="stepper">
          <button class="plusminus" (click)="handleRowsMinus()">-</button>
          <input type="number" class="num" name="rows" [(ngModel)]="formModel.rows" />
          <button class="plusminus" (click)="handleRowsPlus()">+</button>
        </div>
      </div>
    </div>
  </form>

  <div class="actions"></div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start"><app-button (buttonClick)="handleCancel()" text="Cancel" [highlight]="false"></app-button></ion-buttons>
    <ion-buttons slot="end"> <app-button (buttonClick)="handleCreate()" [highlight]="true" text="Create"></app-button></ion-buttons>
  </ion-toolbar>
</ion-footer>
